export enum ValidationMessage {
  TUITION_AMOUNT_REQUIRED = 'Tuition amount is required',
  INSTALLMENT_REQUIRED = 'At least one installment is required',
  INSTALLMENT_AMOUNT_MATCH = 'Installment amount exceeds plan amount',
  PAYMENT_SCHEDULE_CREATED = 'Payment schedule created successfully',
  PAYMENT_PLAN_CANCEL_SUCCESS = 'Payment schedule deleted successfully',
  PAYMENT_PLAN_CANCEL_FAILURE = 'Failed to cancel payment plan',
  SOMETHING_WENT_WRONG = 'Something went wrong. Please try again later',
  NAME_ON_ACCOUNT_REQUIRED = 'Name on account is required',
  NAME_ON_CARD_REQUIRED = 'Name on card is required',
  ROUTING_NUMBER_REQUIRED = 'Routing number is required',
  ACCOUNT_NUMBER_REQUIRED = 'Account number is required',
  CARD_NUMBER_REQUIRED = 'Card number is required',
  CVV_REQUIRED = 'CVV is required',
  EXPIRATION_DATE_REQUIRED = 'Expiration date is required',
  PAYMENT_METHOD_ADDED = 'Payment method added successfully',
  SET_DEFAULT_METHOD_SUCCESS = 'Default payment method set successfully',
  PAYMENT_METHOD_DELETED = 'Payment method deleted successfully',
  AUTO_PAY_SET_TRUE = 'Auto Pay is set successfully',
  AUTO_PAY_SET_FALSE = 'Auto Pay is turned off successfully',
  AUTO_PAY_METHOD_UPDATED = 'Auto Pay method updated successfully',
  PAYMENT_PROCESS_TAKES_LONGER_TIME = 'Your payment is taking longer than expected. We will notify you once the payment is processed.',
  PAYMENT_FAILED = 'Payment failed. Please try again.',
  NO_PAYMENT_SCHEDULE = 'No Payment Method, Please add new.',
  PAYMENT_SCHEDULE_UPDATED_SUCCESSFULLY = 'Payment schedule updated successfully',
  REPORT_GENERATE_SUCCESSFULLY = 'The report is being generated. The report will be sent to your email.',
  REFUND_TRANSACTION = 'Transaction refunded successfully.',
  OWNERSHIP_PERCENTAGE_ERROR = 'The total ownership percentage must be 100 percent or less',
  WEBSITE_LENGTH_VALIDATION_ERROR = 'Website cannot be more than 64 characters',
  PHONE_NUMBER_VALIDATION_ERROR = 'Phone number must be 10 digits',
  NO_SCHOOL_SELECTED = 'Please select a school to generate the report',
}

export enum TransactionStatus {
  DECLINED = 'DECLINED',
  FAILED = 'FAILED',
  REFUNDED = 'REFUNDED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'PAID',
}

export enum PaymentMethod {
  BANK = 'Bank',
  CARD = 'Card',
}

export enum TransactionListSortByKey {
  PaymentDate = 'paymentDate',
  Amount = 'totalAmount',
  Status = 'status',
}

export enum PaymentSchedule {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
}

export enum Tabs {
  INVOICES = 'Invoices',
  TRANSACTIONS = 'Transactions',
}

export enum AccountSummaryStatus {
  CURRENT = 'CURRENT',
  LOA = 'LOA',
  PASTDUE = 'PAST DUE',
  OVERDUE = 'OVERDUE',
}

export enum StudentAccountTabs {
  DASHBOARD = 'Dashboard',
  MYPAYMENTS = 'My Payments',
}

export enum FinancePaymentsTabs {
  INVOICES = 'Invoices',
  TRANSACTIONS = 'Transactions',
}

export enum IFieldType {
  ACH_TYPE = 'ach',
  CARD_TYPE = 'card',
  CVV_TYPE = 'cvv',
}

export enum StorageKey {
  TRANSACTION_LIST_PAGE_FILTERS = 'transactions_list_page_filters',
}

export enum PaymentStatus {
  CURRENT = 'CURRENT',
  OVERDUE = 'OVERDUE',
  'PAST DUE' = 'PAST DUE',
  LOA = 'LOA',
}

export enum PaymentScheduleStatus {
  UPCOMING = 'UPCOMING',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
}

export enum ReportType {
  PDF = 'PDF',
  CSV = 'CSV',
}
